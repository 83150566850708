import React, { useRef, useState, useEffect } from "react";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Draggable,
  moveItems,
  useDraggable,
  useDraggableContext,
} from "react-tiny-dnd";
import { Diet_Type } from "../../generated/graphql";
import { ExtraHotIcon } from "../icons/ExtraHotIcon";
import { HotIcon } from "../icons/HotIcon";
import { MediumIcon } from "../icons/MediumIcon";
import { MildIcon } from "../icons/MildIcon";
import { ALLERGEN_ICONS, ALLERGEN_NAMES } from "../constant/icons";
import UiSwitch from "../toggleButton/UiSwitch";
import useHotelInformation from "../../pages/Hotel-Information/hotel-info-hook";
import { useAutoScroll } from "react-tiny-autoscroll";
import AllergenIcon from "../Icon/AllergenIcon";

const Item = ({
  id,
  name,
  price,
  sequence,
  img,
  currency,
  // category,
  type,
  allergen,
  spiceLevel,
  recommendation,
  isActive,
  listeners,
  isDragging,
  handleDelete,
  handleClickEditOpen,
  onToggle,
}: {
  id: any;
  name: any;
  price: any;
  sequence: any;
  img: any;
  section: any;
  currency: any;
  // category: any;
  type: Diet_Type;
  allergen: any;
  spiceLevel: any;
  recommendation: any;
  isActive: any;
  listeners: any;
  isDragging: any;
  handleDelete: any;
  handleClickEditOpen: any;
  onToggle: any;
}) => {
  const index = Number(id);
  const opacity = isDragging ? 0.5 : 1;

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }
  const chilli = [
    {
      name: "ExtraHotIcon",
      icon: <ExtraHotIcon />,
      level: "Extra Hot",
    },
    {
      name: "HotIcon",
      icon: <HotIcon />,
      level: "Hot",
    },
    {
      name: "MediumIcon",
      icon: <MediumIcon />,
      level: "Medium",
    },
    {
      name: "MildIcon",
      icon: <MildIcon />,
      level: "Mild",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, opacity, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: "1 1 1%",
          alignItems: "center",
        }}
      >
        {sequence}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        <img src={img} width={70} height={70} alt={name}></img>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {name}
      </div>
      {/* <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {category}
      </div> */}
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {type === Diet_Type.Vegan && (
          <Box
            component="img"
            style={{ paddingRight: "7px" }}
            src={`/assets/veg.png`}
          ></Box>
        )}
        {type === Diet_Type.NonVegan && (
          <Box
            component="img"
            style={{ paddingRight: "7px" }}
            src={`/assets/non-veg.png`}
          ></Box>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        <Stack
          style={{ marginTop: "2px", paddingRight: "70px" }}
          direction="row"
          alignItems="center"
        >
          <>
            {allergen?.map((all: any, i:number) => {
              const icon = (ALLERGEN_ICONS as any)[all];
              const name = (ALLERGEN_NAMES as any)[all];

              return (
                icon ?
                <Tooltip title={name} key={i}>
                  <Typography sx={{ pr: 1 }}>{icon}</Typography>
                </Tooltip>
                :
                <AllergenIcon source={all} key={i}/>
              );
            })}
          </>
        </Stack>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {chilli
          .filter((ic) => ic.name === spiceLevel)
          .map((ic, index) => (
            <MenuItem key={ic.name} value={ic.name}>
              <ListItemIcon>{ic.icon}</ListItemIcon>
            </MenuItem>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {recommendation ? "Yes" : "No"}
      </div>
      <div
        style={{
          display: "flex",
          flex: "0.5 1 1%",
          alignItems: "center",
        }}
      >
        {currency} {price}
      </div>
      <div
        style={{
          display: "flex",
          flex: 0,
          alignItems: "center",
        }}
      >
        {}
      </div>
      <UiSwitch
        checked={isActive != null ? isActive : true}
        onChange={() => onToggle()}
        label=""
      />
      <IconButton
        onClick={() => handleClickEditOpen()}
        color="info"
        aria-label="edit"
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        onClick={() => handleDelete()}
        color="error"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
      <div className="dnd-icon" {...listeners}>
        <DragIndicatorIcon />
      </div>
    </Box>
  );
};

const DraggableRow = ({
  index,
  context,
  item,
  handleDelete,
  handleClickEditOpen,
  onToggle,
}: {
  index: any;
  context: any;
  item: any;
  handleDelete: any;
  handleClickEditOpen: any;
  onToggle: any;
}) => {
  const {
    listeners, // Handler listeners can be passed to Draggable component as well
    isDragging,
  } = useDraggable(context, index);
  const { Details } = useHotelInformation();

  return (
    <Draggable context={context} key={item.id} index={index}>
      <Item
        id={item.id}
        sequence={index + 1}
        name={item.name}
        price={item.price}
        section={item.section}
        // category={item.type}
        currency={Details?.currency}
        type={item.isVegan}
        allergen={item.allergens}
        spiceLevel={item.spice_level}
        recommendation={item.is_recommended}
        isActive={item.is_Activated}
        img={item.img}
        listeners={listeners}
        isDragging={isDragging}
        handleDelete={() => {
          handleDelete?.(item.id);
        }}
        handleClickEditOpen={() => {
          handleClickEditOpen?.(item);
        }}
        onToggle={() =>
          onToggle(
            item.is_Activated != null ? !item.is_Activated : false,
            item.id || ""
          )
        }
      />
    </Draggable>
  );
};

const DraggableItem = ({
  itemArray,
  handleDelete,
  handleClickEditOpen,
  onToggle,
  onSave,
}: {
  itemArray: any;
  handleDelete: any;
  handleClickEditOpen: any;
  onToggle: any;
  onSave: any;
}) => {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (itemArray) {
      setItems(
        itemArray.sort(
          (a: any, b: any) => (a?.sequence || 0) - (b?.sequence || 0)
        )
      );
    }
  }, [itemArray]);

  const onDrop = async (dragIndex: number, overIndex: number) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
    nextItems.filter((item, i) => onSave({ ...item, sequence: i }));
  };

  const context = useDraggableContext({
    onDrop,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  useAutoScroll({ containerRef, skip: !context.isDragging });

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          border: "1px solid #ddd",
          overflowY: "auto",
          borderRadius: "4px",
          m: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            borderBottom: "1px solid #eee",
          }}
          style={{
            height: "initial",
            opacity: 1,
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "1 1 0%",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            {"Sequence"}
          </div>
          <div
            style={{
              display: "flex",
              flex: "0.6 0 5%",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            {"Image"}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            {"Name"}
          </div>
          {/* <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            {"Category"}
          </div> */}
          <div
            style={{
              display: "flex",
              flex: 1,
              fontWeight: "bold",
            }}
          >
            {"Type"}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              fontWeight: "bold",
            }}
          >
            {"Allergen"}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              fontWeight: "bold",
            }}
          >
            {"Spice Level"}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              fontWeight: "bold",
            }}
          >
            {"Recommendation"}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            {"Price"}
          </div>
          <div
            style={{
              display: "flex",
              flex: "0.5",
              alignItems: "center",
              fontWeight: "bold",
            }}
          ></div>
        </Box>
        {itemArray
          ?.sort((a: any, b: any) => (a?.sequence || 0) - (b?.sequence || 0))
          ?.map((item: any, i: any) => (
            <DraggableRow
              context={context}
              key={item.id}
              index={i}
              item={item}
              handleDelete={handleDelete}
              handleClickEditOpen={handleClickEditOpen}
              onToggle={onToggle}
            />
          ))}
      </Box>
    </>
  );
};

export default DraggableItem;
