import { Grid, Typography, Box } from "@mui/material";
// import { Add, EditOutlined } from "@mui/icons-material";
import React from "react";

import Loader from "../../components/Loader";
import useUsers from "./users-hooks";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridExperimentalFeatures,
} from "@mui/x-data-grid";
// import DeleteIcon from "@mui/icons-material/Delete";

export default function Users() {
  const {
    users,
    loading,
  } = useUsers();
  if (loading) {
    return <Loader />;
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
        <GridToolbarFilterButton
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  const columnsForItineraryList: GridColDef[] = [
    {
      field: "profile_url",
      headerName: "Profile Image",
      width: 150,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 200,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 200,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "role",
      headerName: "Job Role",
      sortable: false,
      width: 300,
    },
  ];

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Users Management
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={users || []}
          columns={columnsForItineraryList}
          pageSize={9}
          rowsPerPageOptions={[9]}
          density="comfortable"
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={
            { newEditingApi: true } as Partial<GridExperimentalFeatures>
          }
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </>
  );
}
