import { Add, EditOutlined } from "@mui/icons-material";
import {
  Grid,
  Typography,
  IconButton,
  Stack,
  Tooltip,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import Loader from "../../components/Loader";
import useAllergen from "./allergen-hooks";
import AllergenForm from "./allergens-form";
import { EggIcon } from "../../components/icons/EggIcon";
import { FishIcon } from "../../components/icons/FishIcon";
import { MilkIcon } from "../../components/icons/MilkIcon";
import { PeanutsIcon } from "../../components/icons/PeanutsIcon";
import { ShelfishIcon } from "../../components/icons/Shelfishicon";
import { SoybeansIcon } from "../../components/icons/SoybeansIcon";
import { TreenutsIcon } from "../../components/icons/TreenutsIcon";
import { WheatIcon } from "../../components/icons/WheatIcon";
import {
  DataGrid,
  GridColDef,
  GridExperimentalFeatures,
} from "@mui/x-data-grid";
import AllergenIcon from "../../components/Icon/AllergenIcon";
const HotelAllergens = () => {
  const {
    Allergens,
    handleClickEditOpen,
    editData,
    handleEditClose,
    open,
    propertyId,
    refetchAllergen,
    setOpen,
    loadingAllergens,
    handleDelete,
    isCustomized,
    setIsCustomized
  } = useAllergen();

  if (loadingAllergens) {
    return <Loader />;
  }

  const icons = [
    {
      name: "FishIcon",
      icon: <FishIcon />,
    },
    {
      name: "MilkIcon",
      icon: <MilkIcon />,
    },
    {
      name: "TreenutsIcon",
      icon: <TreenutsIcon />,
    },
    {
      name: "WheatIcon",
      icon: <WheatIcon />,
    },
    {
      name: "ShelfishIcon",
      icon: <ShelfishIcon />,
    },
    {
      name: "PeanutsIcon",
      icon: <PeanutsIcon />,
    },
    {
      name: "SoybeansIcon",
      icon: <SoybeansIcon />,
    },
    {
      name: "EggIcon",
      icon: <EggIcon />,
    },
  ];

  const columnsForOrderList: GridColDef[] = [
    {
      field: "name",
      headerName: "Allergen Name",
      width: 350,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "icon",
      headerName: "Icon",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
      renderCell: (params) => {

        const iconInfo = icons.find((icon) => icon.name === params.row.icon);

        if (!iconInfo && !params.row.uploaded_icon) {
          return <div>No Icon</div>;
        }

        return (
          iconInfo ?
          <Tooltip enterTouchDelay={0} title={iconInfo.name}>
            <Typography sx={{ pr: 1 }}>{iconInfo.icon}</Typography>
          </Tooltip>
          :
          <AllergenIcon source={params.row.uploaded_icon}/>
        );
      },
    },
    {
      field: "id",
      headerName: "Action",
      // width: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              onClick={() => handleClickEditOpen(params.row)}
              color="info"
              aria-label="edit"
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              onClick={() => handleDelete(params.row.id)}
              color="error"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Allergen Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Allergen
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Box sx={{ height: 765, width: "100%" }}>
          <DataGrid
            rows={Allergens || []}
            columns={columnsForOrderList}
            pageSize={9}
            rowsPerPageOptions={[9]}
            density="comfortable"
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={
              { newEditingApi: true } as Partial<GridExperimentalFeatures>
            }
          />

          {/* Edit Dialog */}
          <AllergenForm
            editData={editData}
            handleEditClose={handleEditClose}
            open={open}
            propertyId={propertyId}
            refetch={refetchAllergen}
            setOpen={setOpen}
            isCustomized={isCustomized}
            setIsCustomized={setIsCustomized}
          />
        </Box>
      </Grid>
    </>
  );
};

export default HotelAllergens;
