import { Typography, Divider } from "@mui/material";
import HotelIcon from "@mui/icons-material/Hotel";
import LivingIcon from "@mui/icons-material/Living";
import DiningIcon from "@mui/icons-material/Dining";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RoomServiceIcon from "@mui/icons-material/RoomService";
import CategoryIcon from '@mui/icons-material/Category';
import BackspaceIcon from '@mui/icons-material/Backspace';
import EventIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/Settings';
import { NavLink } from "react-router-dom";
import * as React from "react";

const NavList = () => {
  const NavData = [
    {
      name: "Hotel Information",
      icon: <HotelIcon />,
      path: "/",
    },
    {
      name: "Facilities",
      icon: <RoomServiceIcon />,
      path: "/hotel-services",
    },
    {
      name: "Services",
      icon: <RoomServiceIcon />,
      path: "/services",
    },
    {
      name: "Rooms",
      icon: <LivingIcon />,
      path: "/rooms",
    },
    // {
    //   name: "Dining",
    //   icon: <LocalBarIcon/>,
    //   path: "/dining",
    // },
    {
      name: "Dine In Menu",
      icon: <DiningIcon />,
      path: "/hotel-edible",
    },
    {
      name: "Promotions",
      icon: <LocalOfferIcon />,
      path: "/promotions",
    },
    {
      name: "Dine In Categories",
      icon: <CategoryIcon />,
      path: "/categories",
    }, 
    {
      name: "Dine In Sections",
      icon: <CategoryIcon />,
      path: "/sections",
    }, 
    {
      name: "Allergens",
      icon: <BackspaceIcon />,
      path: "/allergens",
    },
    {
      name: "Reports",
      icon: <BackspaceIcon />,
      path: "/reports",
    },
    {
      name: "Itinerary",
      icon: <EventIcon />,
      path: "/itinerary",
    },
    {
      name: "Users",
      icon: <SettingsIcon />,
      path: "/users",
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      path: "/settings",
    },
  ];

  return (
    <div className="px-2">
      <Typography
        sx={{
          mx: 7,
          my: 2,
          display: { xs: "none", md: "flex" },
          fontWeight: "bold",
        }}
        width={"150px"}
        src={"https://ennovatech.com/assets/images/company-logo/hotlync.svg"}
        component={"img"}
      />
      <Divider />
      <div className="px-2 mt-3">
        {NavData.map((v) => {
          return (
            <NavLink to={v.path} className="sidebar-list" key={v.name}>
              <span>{v.icon}</span>
              <h4 className="mb-0">{v.name}</h4>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default NavList;
